@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.container {
  position: relative;
  cursor: pointer;
  width: max-content;
  height: max-content;
  &-header {
    all: unset;
    outline: 2px solid var(--cst-droplist-value-outline);
    outline-offset: -2px;

    border-radius: var(--cornerradius10);

    @include min-1024-break {
      border-radius: var(--cornerradius6);
    }

    @include min-1440-break {
      border-radius: var(--cornerradius10);
    }

    &:hover {
      > div {
        background: var(--cst-drop-listsort-background-hover);
        > div > p {
          color: var(--cst-droplist-value-text-default);
        }
      }
    }

    &-opened {
      pointer-events: none;
    }
  }
}

.sheet {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px;

  @include min-428-break {
    min-width: 428px;
    gap: 14px;
  }

  &-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    @include min-428-break {
      gap: 10px;
    }
  }
}

.currency_item,
.skeleton {
  display: flex;
  width: 100%;
  align-items: center;
  background: var(--cst-dropitem-value-background-default);

  &__selected {
    p {
      opacity: 0.5;
    }
    pointer-events: none;
  }
  > div {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  p {
    color: var(--cst-droplist-value-text-default);

    @include typography-s;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-base;
    }

    @include min-1024-break {
      @include typography-xs;
    }

    @include min-1440-break {
      @include typography-base;
    }
  }

  padding: 12px;
  @include fixedSize(74px, 42px);
  gap: 8px;

  @include min-428-break {
    padding: 12.5px 14px;
    @include fixedSize(92px, 46px);
    gap: 18px;
  }

  @include min-1024-break {
    border-radius: var(--cornerradius6);
    padding: 8.5px 8px;
    @include fixedSize(66px, 32px);
    gap: 10px;
  }

  @include min-1440-break {
    border-radius: var(--cornerradius10);
    padding: 12.5px 12px;
    @include fixedSize(84px, 46px);
    gap: 12px;
  }

  &:hover {
    background-color: var(--cst-dropitem-background-hover);
    div > p {
      color: var(--cst-dropitem-text-hover);
    }
  }

  p[id='list-item-title'] {
  }
}

.drop_down {
  display: flex;
  flex-direction: column;
  background: var(--cst-droplist-value-background);
  gap: 4px;
  overflow: hidden;
  outline: 2px solid var(--cst-droplist-value-outline);
  outline-offset: -2px;
  margin-top: 6px;
  box-shadow: var(--elevation-1);

  @include min-1024-break {
    border-radius: var(--cornerradius6);
  }
  @include min-1440-break {
    border-radius: var(--cornerradius10);
    margin-top: 3px;
  }
}

.icon {
  transition: all 0.2s;
  @include singleFixedSize(18px);
  @include min-1024-break {
    @include singleFixedSize(14px);
  }
  @include min-1440-break {
    @include singleFixedSize(18px);
  }
  &-active {
    transform: rotate(180deg);
  }
}
